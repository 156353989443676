
export default {
    data: () => ({

    }),
    computed: {

    },
    methods: {
        //过滤字典
        filterStatus:function(arr,val){
            let status =  arr.filter(function(item){
                return item.key == val ;
            })
            if(status != ''){
                return status[0]; //返回筛选的对象
            } else{
                return {};
            }
        },
    },
    mounted() {
    }


}
