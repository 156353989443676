//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BizSetting from './components/biz-setting';

export default {
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        BizSetting,
    },
    data: function () {
        return {
            form: {
                productId: '',
                productName: '',
                settleCompanyId: '',
                settleCompanyName: '',
                inviteUrl: '',
                sortNumber: 0,
                productBizList: [],
            },
        };
    },
    methods: {
        cancle: function (val) {
            this.$emit('close', val);
        },
        submit: function () {
            this.$post(
                `${this.$store.getters.indexUrl}/settle/settProduct/save`,
                this.form,
                (data) => {
                    showMsg('添加成功', 'success');
                    this.cancle('submit');
                },
                true
            );
        },
        getDetail: function () {
            this.$get(
                `${this.$store.getters.indexUrl}/settle/settProduct/getInfo`,
                {
                    productId: this.data.productId,
                },
                (data) => {
                    this.form = data;

                    if (!data.productBizList.length) {
                        // 为空的话，就增加一行
                        this.$nextTick(() => {
                            this.$refs.bizSetting.add();
                        });
                    }
                }
            );
        },
    },
    mounted() {
        if (!this.data.productId) {
            return;
        }

        this.getDetail();
    },
};
