//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MODEL from '@mixins/model.js';

export default {
    mixins: [MODEL],
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tableData: [], // 产品列表
        };
    },
    methods: {
        //添加
        add() {
            var obj = {
                bizType: '',
                bizName: '',
            };

            this.model.push(obj);
        },
        //删除
        del(index) {
            return this.model.splice(index, 1);
        },
    },
    mounted() {
        if (this.disabled) {
            return;
        }

        this.add();
    },
};
