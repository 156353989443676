//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LIST_MIXIN from '@mixins/list-page';
import COMMON_METHODS from '@mixins/common-methods.js';
import FormPage from './form';

export default {
    mixins: [LIST_MIXIN, COMMON_METHODS],
    props: [],
    components: {
        FormPage,
    },
    data: function () {
        return {
            pgData: {
                productId: '',
                productName: '',
            },
            tableData: [],

            dialogTitle: '',
            dialogVisible: false,

            row: {},
        };
    },
    computed: {},
    watch: {},

    methods: {
        doSearch: function () {
            this.$refs.page.queryData();
        },

        reload: function () {
            this.$refs.page.reload();
        },

        addHandler() {
            this.row = {};
            this.dialogTitle = '添加产品';
            this.dialogVisible = true;
        },

        canalInviteDialogClose(val) {
            this.dialogVisible = false;
            if (val === 'submit') {
                this.doSearch();
            }

            if (val === 'edit') {
                this.reload();
            }
        },

        editHandler(row) {
            this.row = row;
            this.dialogTitle = '编辑产品';
            this.dialogVisible = true;
        },
    },
    mounted() {},
};
